@import '~@aspera-ui/starter/styles/spacing';
@import '~@aspera-ui/starter/styles/typography';
@import '~@aspera-ui/starter/styles/colors';

.home {
  .logic-section {
    @include spacing-06(true, false, true, false, false);
    @include spacing-06(false, false, true, false, false);

    &:not(:last-child) {
      @include color(border-bottom-color, ui-04);

      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    .sub-heading {
      @include heading-03();
      @include spacing-05(true, false, true, false, false);
    }

    .link-list {
      @include spacing-06(true, false, false, false, true);
    }

    .helper-tile--wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1.5rem;
      justify-content: flex-start;

      .helper-tile {
        cursor: pointer;
        display: flex;
        flex: 0 0 20rem;
        max-width: 20rem;
        position: relative;
      }
    }
  }

  .connect-init-results {
    .cds--btn {
      @include spacing-05(true, false, true, false, false);
    }

    .cds--inline-notification {
      @include spacing-05(true, false, true, false, false);
    }
  }
}
