@import '~@aspera-ui/starter/styles/spacing';

.allow-connect-prompt {
  .image-item {
    @include spacing-04(true, false, true, false, false);

    display: block;
    max-width: 80%;
  }
}
