@import '~@aspera-ui/starter/styles/typography';
@import '~@aspera-ui/starter/styles/spacing';

.cds--switcher__item a {
  @include overflow-ellipsis();
}

.aspera-user-menu {
  div.user-information {
    display: none;
  }

  .cds--switcher__item:last-child {
    display: none;
  }
}

.app-wrapper p {
  @include spacing-05(true, false, true, false, false);
  @include body-short-01();

  &.bold-text {
    @include bold-label();
  }
}

.transfer-list button[data-auto='transfer-activity-item-action-troubleshoot'] {
  display: none;
}
