@import '~@aspera-ui/starter/styles/spacing';

.diagnostic-results {
  @include spacing-05(true, false, true, false, false);

  .aspera-code-snippet {
    .text-area {
      min-height: 21rem;
    }
  }
}
